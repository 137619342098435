import {environment} from "@/auth/environment";

export default class DashboardService {
    // Will be used by this service for making API calls
    axiosIns = null
    useJwt = null
    jwtConfig = null


    constructor(useJwt) {
        this.useJwt = useJwt
        this.jwtConfig = useJwt.jwtConfig
        this.axiosIns = useJwt.axiosIns

    }


    get_events(company_id) {
        return this.axiosIns.get(environment.baseURL + "/dashboard/events/" + company_id)
    }

    get_events_in_period(company_id, data) {
        return this.axiosIns.post(environment.baseURL + "/dashboard/eventsInPeriod/" + company_id, data)
    }

    get_events_in_period_admin(data) {
        return this.axiosIns.post(environment.baseURL + "/dashboard/eventsInPeriodAdmin", data)
    }

    get_stats(company_id) {
        return this.axiosIns.get(environment.baseURL + "/dashboard/stats/" + company_id)
    }


}
